import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {
  "title": "Testing av Universell utforming",
  "template": "default",
  "introtext": "Her finner du testveiledning for de lovpålagte uukravene"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`Introduksjon`}</h2>
    <p>{`Det er viktig å kvalitetssikre implementeringen av Designsystemets komponenter i kontekst av siden.
Dette betyr at selv om komponentene er grunnleggende utviklet for å imøtekomme WCAG-standarden, vil det være nødvendig å sjekke at alt fungerer som det skal når de ulike komponentene benyttes.
På denne siden finner du en oversikt og gjennomgang av relevante WCAG testkriteria.
Ikke-relevante kriterier vil foreløpig ikke være inkludert på denne siden.`}</p>
    <p>{`Hver enkelt komponent vil lenke direkte til testkriterie de må testes for.`}</p>
    <h2>{`Prinsipp 1: Mulig å oppfatte`}</h2>
    <h3>{`Ikke-tekstlig innhold (1.1.1)`}</h3>
    <ul>
      <li parentName="ul">{`Inspiser koden.`}</li>
      <li parentName="ul">{`Hvis det ikke-tekstlige innholdet vil gi nyttig informasjon med kompenserende teknologi
som for eksempel skjermlesere, sjekk at det har tekstalternativ eller navn spesifisert i
koden (alt-tekst, aria-label, aria-labelledby, title, label, ...). Standard
HTML-punktlister trenger for eksempel ikke tekstalternativ for at kulepunktene skal presenteres riktig
med kompenserende teknologi. Se bare at standard ul- og li-elementer er brukt.`}</li>
      <li parentName="ul">{`Hvis det ikke-testlige innholdet er pynt eller ikke vil gi nyttig informasjon med
kompenserende teknologi, sjekk at det er tilstrekkelig skjult for kompenserende teknologi
i koden (alt-tekst som står tom, aria-hidden true, ...).`}</li>
      <li parentName="ul">{`Aktiver skjermleser.`}</li>
      <li parentName="ul">{`For ikke-tekstlig innhold som skal fanges opp av kompenserende teknologi, test at
tekstalternativet eller navnet leses opp av skjermleseren. For standard HTML-punktlister,
sjekk at kulepunktene leses opp (typisk sier skjermleser for eksempel punkttegn).`}</li>
      <li parentName="ul">{`For innhold som skal være skjult, test at dette ikke leses opp.`}</li>
    </ul>
    <h3>{`Informasjon og relasjoner (1.3.1)`}</h3>
    <h4>{`Heading`}</h4>
    <ul>
      <li parentName="ul">{`Inspiser koden.`}</li>
      <li parentName="ul">{`Sjekk om overskriften er kodet med enten standard HTML, spesifikt `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`h1`}</code>{` til og med `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`h6`}</code>{`.`}</li>
      <li parentName="ul">{`Kontrollér at overskriftsnivået i koden er det samme som det visuelle nivået til overskriften i grensesnittet.`}</li>
      <li parentName="ul">{`Sjekk at tittelen til tabellen aka tabelloverskriften ligger rett før tabellen uten tekst mellom tittel og tabell.`}</li>
      <li parentName="ul">{`Sjekk at tabeltittelen er kodet med `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`caption`}</code>{`-elementet for å knytte den til tabellen kodemessig.`}</li>
      <li parentName="ul">{`Kontrollér at tabelltittelen beskriver innholdet i tabellen på en god måte.`}</li>
    </ul>
    <h4>{`Tabeller`}</h4>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`Inspiser koden.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Kontrollér at tabellen er kodet med `}<code parentName="p" {...{
            "className": "language-text"
          }}>{`table`}</code>{`-elementet og at overskriftscellene er kodet med `}<code parentName="p" {...{
            "className": "language-text"
          }}>{`th`}</code>{`.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`For komplekse tabeller kodet med med scope:`}</p>
        <ul parentName="li">
          <li parentName="ul"><code parentName="li" {...{
              "className": "language-text"
            }}>{`scope="col"`}</code>{` når overskriftscellen er overskrift for en kolonne.`}</li>
          <li parentName="ul"><code parentName="li" {...{
              "className": "language-text"
            }}>{`scope="colgroup"`}</code>{` når overskriftscellen er overskrift for flere kolonner.`}</li>
          <li parentName="ul">{`scope="row" når overskriftscellen er overskrift for en rad.`}</li>
          <li parentName="ul">{`scope="rowgroup" når overskriftscellen er overskrift for flere rader.`}</li>
        </ul>
      </li>
    </ul>
    <h4>{`Lister`}</h4>
    <ul>
      <li parentName="ul">{`Inspiser koden. `}</li>
      <li parentName="ul">{`Se at komponenten er kodet som liste, med riktig listetype. Se også at de indre
elementene (`}<code parentName="li" {...{
          "className": "language-text"
        }}>{`li`}</code>{`/`}<code parentName="li" {...{
          "className": "language-text"
        }}>{`dt`}</code>{`/`}<code parentName="li" {...{
          "className": "language-text"
        }}>{`dd`}</code>{`) ligger inne i de ytre elementene (`}<code parentName="li" {...{
          "className": "language-text"
        }}>{`ul`}</code>{`/`}<code parentName="li" {...{
          "className": "language-text"
        }}>{`ol`}</code>{`/`}<code parentName="li" {...{
          "className": "language-text"
        }}>{`dl`}</code>{`).`}</li>
      <li parentName="ul">{`Aktiver skjermleser.`}</li>
      <li parentName="ul">{`Sjekk at det leses opp at komponenten er en liste og at alle liste-elementene leses
opp som en del av listen.`}</li>
    </ul>
    <h3>{`Meningsfylt rekkefølge (1.3.2)`}</h3>
    <p>{`Sikre at leserekkefølgen på innhold er den samme med CSS skrudd av som vanlig visning.
Dette betyr at rekkefølgen må være identisk, eller en rekkefølge som presenterer samme mening/funksjon.`}</p>
    <ul>
      <li parentName="ul">{`Kontrollér rekkefølgen av innholdet på nettsiden.`}</li>
      <li parentName="ul">{`Skru av CSS/stilark i nettleseren.`}</li>
      <li parentName="ul">{`Se om rekkefølgen eller formålet med innholdet er det samme som med CSS/stilark skrudd på.`}</li>
    </ul>
    <h3>{`Bruk av farge (1.4.1)`}</h3>
    <p>{`Observer komponenten i ulike tilstander. Hvis farge brukes som en måte til å
formidle informasjon til brukeren må du sjekke at informasjonen også gis på andre måter.`}</p>
    <ul>
      <li parentName="ul">{`Hvis informasjonen bare formidles med farger, sjekk at fargene skiller seg fra hverandre med en kontrast på minimum 3:1. `}</li>
      <li parentName="ul">{`Hvis brukerne må kunne identifisere en spesifikk farge for å forstå informasjonen, for eksempel om rammen på et inputfelt er grønn for gyldig eller rød for ugyldig, er det ikke nok med bare fargekontrasten. Da kreves det at vi har andre visuelle indikatorer i tillegg.`}</li>
    </ul>
    <h3>{`Kontrast (1.4.3)`}</h3>
    <p>{`Test fargen på teksten i komponenten mot bakgrunnsfargen til komponenten (hex-kodene). `}</p>
    <ul>
      <li parentName="ul">{`Bruk en kontrastsjekker som f.eks. Color Contrast Analyser (TPGi). `}</li>
      <li parentName="ul">{`Hvis fargene endrer seg i ulike tilstander av komponenten, for eksempel ved hover, husk å teste disse også.`}</li>
      <li parentName="ul">{`Tekststørrelsen i komponentene kan variere fordi den settes basert på brukerens nettleserinnstillinger. `}</li>
      <li parentName="ul">{`For å sikre at kravet er ivaretatt på alle ulike tekststørrelser baserer vi testene på det høyeste kontrastkravet (4.5:1).`}</li>
    </ul>
    <h3>{`Endring av tekststørrelse (1.4.4)`}</h3>
    <p>{`Tekst kan bli endret til 200 % størrelse uten tap av innhold eller funksjon.`}</p>
    <ul>
      <li parentName="ul">{`Inspiser koden. `}</li>
      <li parentName="ul">{`Sjekk at all tekst i komponenten er spesifisert i et skalerbart format, for eksempel rem. Dette gjelder også komponenten om du definerer høyde.`}</li>
      <li parentName="ul">{`Øk tekststørrelsen til 200% i nettleser-innstillingene. Metode avhenger av nettleser.
Hvis du bruker Chrome, øk tekststørrelsen på html-taggen til 200%. Det er viktig at endringen skjer på HTML-taggen, om du zoomer i body kan du potensielt få feil resultat.`}</li>
      <li parentName="ul">{`Se at teksten i komponenten endrer størrelse.`}</li>
      <li parentName="ul">{`Se at alt innhold og all funksjonalitet i komponenten fortsatt er tilgjengelig og
forståelig (innhold overlapper ikke annet innhold, innhold blir ikke kuttet så det
ikke er synlig).`}</li>
      <li parentName="ul">{`Sjekk i både desktop og mobilvisning. Bredde 320px kan brukes for å teste mobilvisning.`}</li>
    </ul>
    <p>{`Ved bruk av ord med litt lengde, i mobilvisning, kan innhold flyte ut over containeren til komponenten samt skjermen og intodusere horisontal skroll. Dette er et tolkningsspørsmål og skal ikke være brudd på krav 1.4.4. siden innholdet fortsatt er tilgjengelig og forståelig. Det anbefales likevel å forsøke å unngå dette så langt det er mulig.`}</p>
    <h3>{`Bilde av tekst (1.4.5)`}</h3>
    <p>{`Unngå bilde av tekst med mindre dette kan direkte tilpasses av brukeren til deres behov.`}</p>
    <p>{`Logoer eller lignende med tilfredsstillende alt-tekst er akseptabelt.`}</p>
    <h2>{`Prinsipp 2: Mulig å betjene`}</h2>
    <h3>{`Tastatur (2.1.1)`}</h3>
    <p>{`Alt innhold skal være tilgjengelig med tastaturnavigasjon.`}</p>
    <p>{`Bruk tab-tasten til å navigere deg gjennom nettsiden og sørg for at interaktive elementer kan brukes med enter eller mellomromstasten.`}</p>
    <h3>{`Ingen tastaturfelle (2.1.2)`}</h3>
    <p>{`Det skal være mulig å navigere seg ut av interaktive elementer når man benytter tastaturnavigasjon.`}</p>
    <ul>
      <li parentName="ul">{`Bruk tastaturet og naviger til komponenten med tab-tasten.`}</li>
      <li parentName="ul">{`Naviger deg videre til neste element på siden for å sjekke at fokuset ikke er «fanget» i komponenten.`}</li>
    </ul>
    <h3>{`Hoppe over blokker (2.4.1)`}</h3>
    <p>{`Brukeren skal ha mulighet til å hoppe over gjentagende informasjon (typ header meny) med "hopp til hovedinnhold" og "hopp til navigasjon" lenker.`}</p>
    <h3>{`Sidetitler (2.4.2)`}</h3>
    <p>{`Nettsider skal ha sidetitler som beskriver den aktuelle sidens emne eller formål.`}</p>
    <ul>
      <li parentName="ul">{`Inspiser koden.`}</li>
      <li parentName="ul">{`Sjekk at `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`title`}</code>{` elementet inneholder en tittel som korrekt beskriver hovedinnholdet eller hovedformålet til siden.`}</li>
      <li parentName="ul">{`Dette gjelder også for digitale dokumenter.`}</li>
    </ul>
    <h3>{`Fokusrekkefølge (2.4.3)`}</h3>
    <p>{`Interaktive elementer skal ha en logisk tastaturrekkefølge på samme måte som den visuelle rekkefølgen.`}</p>
    <ul>
      <li parentName="ul">{`Naviger deg gjennom nettsiden med tab-tasten på tastaturet.`}</li>
      <li parentName="ul">{`Sjekk at de interaktive elementene er tilgjengelige med tastatur.`}</li>
      <li parentName="ul">{`Sjekk at rekkefølgen på de interaktive elementene henger sammen med den visuelle rekkefølgen i grensesnittet.`}</li>
    </ul>
    <h3>{`Formål med lenke i kontekst (2.4.4)`}</h3>
    <p>{`Alle lenkers mål (der brukeren ender opp) og funksjon fremgår tydelig av lenketeksten.`}</p>
    <p>{`Lenker som inneholder tekst oppfyller kravet dersom en av følgende er oppfylt:`}</p>
    <ul>
      <li parentName="ul">{`Lenketeksten alene beskriver formålet med lenken.`}</li>
      <li parentName="ul">{`Lenketeksten sammen med programmeringsmessig bestemt lenkekontekst beskriver formålet med lenken. `}</li>
      <li parentName="ul">{`For programmatisk bestemt lenkekontekst vurderes tekst som står i samme avsnitt, liste eller celle i en tabell som lenken gjør eller tilsvarende, eller en tabelloverskrift som er knyttet til tabellcellen som lenken står i eller tilsvarende.`}</li>
    </ul>
    <p>{`Krav til samsvar er lik for apper og dokumenter.`}</p>
    <h3>{`Flere måter (2.4.5)`}</h3>
    <p>{`Nettsider som består av mer enn én side i strukturen, må ha flere måter å finne informasjon og undersider på.`}</p>
    <p>{`Eksempler for ulike metoder er Søk, Meny eller nettstedskart.`}</p>
    <h3>{`Overskrifter og ledetekster (2.4.6)`}</h3>
    <p>{`Sørg for at overskrifter og ledetekster (labels) beskriver tema eller formål på en god måte for sluttbrukeren.`}</p>
    <h3>{`Synlig fokus (2.4.7)`}</h3>
    <p>{`Naviger deg gjennom nettsiden, kontrollér at alle interaktive elementer har synlig fokus ved tastaturnavigasjon.`}</p>
    <h2>{`Prinsipp 3: Forståelig`}</h2>
    <h3>{`Språk på siden (3.1.1)`}</h3>
    <p>{`Riktig språk for nettsiden defineres i koden.`}</p>
    <p>{`Inspiser koden.`}</p>
    <ul>
      <li parentName="ul">{`Sjekk at `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`lang`}</code>{` attributet i `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`html`}</code>{` taggen gir riktig språk-klassifisering for innholdet på siden.`}</li>
    </ul>
    <h3>{`Språk på deler av innhold (3.1.2)`}</h3>
    <p>{`Om enkelte deler av det tekstlige innholdet på siden er på et annet språk enn hovedinnholdet må dette klassifiseres i koden.`}</p>
    <p>{`Inspiser koden.`}</p>
    <ul>
      <li parentName="ul">{`Sjekk at `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`lang`}</code>{` attributet blir brukt i `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`p`}</code>{`taggen for å giriktig språk-klassifisering spesifikt for innholdet som er på et annet språk enn hovedinnholdet`}</li>
    </ul>
    <h3>{`Fokus (3.2.1)`}</h3>
    <p>{`Et element som får tastaturfokus skal ikke automatisk aktivere kontekstuell endring.`}</p>
    <p>{`Gå gjennom nettsiden med tastaturet.
Sjekk at store endringer i grensesnittet ikke aktiveres ved fokus på elementer.`}</p>
    <p>{`"Store endringer" kan være:`}</p>
    <ul>
      <li parentName="ul">{`Åpning av nytt vindu eller fane.`}</li>
      <li parentName="ul">{`Fokus flyttes til en annen komponent.`}</li>
      <li parentName="ul">{`Ny side lastes inn eller brukeren tas til ny side.`}</li>
      <li parentName="ul">{`Innhold blir omorganisert og får ny rekkefølge.`}</li>
    </ul>
    <p>{`UU-Tilsynet har klassifisert "fokus" til å bety tastaturfokus for minstekravet.`}</p>
    <h3>{`Inndata (3.2.2)`}</h3>
    <p>{`Sørg for at skjema-input ikke aktiverer større endringer på siden.`}</p>
    <p>{`Om større endringer vil aktiveres ved input må brukeren vasles på forhånd.`}</p>
    <p>{`Dette kravet gjelder for inputfelt eller interaksjon med avkrysningsboks, radioknapp og lignende.`}</p>
    <p>{`Om kontekstendringen skjer ved klikk på innsendingsknapp, lenke eller annen "ferdigstill" eller "avbryt" funksjon, er dette innenfor.`}</p>
    <h3>{`Konsekvent navigering (3.2.3)`}</h3>
    <p>{`Navigasjonselementer som gjentar seg på flere sider (typ header og footer) må ha samme rekkefølge gjennom hele nettstedet eller tjenesten.`}</p>
    <h3>{`Konsekvent identifikasjon (3.2.4)`}</h3>
    <p>{`Komponenter som har samme funksjonalitet eller inneholder samme type informasjon gjennom nettstedet eller tjenesten må utformes likt.`}</p>
    <p>{`Dette kan for eksempel være "legg til" funksjon, "utvid" funksjon osv.
Disse komponentene må bruke samme ikon og alternativ tekst og labeling.`}</p>
    <h3>{`Identifikasjon av feil (3.3.1)`}</h3>
    <p>{`Feil som oppdages automatisk, i for eksempel skjemafelt eller lignende, må gi en tydelig markering av hvor feilen er og hvordan brukeren kan fikse feilen.`}</p>
    <p>{`Testing:`}</p>
    <ul>
      <li parentName="ul">{`Gå gjennom skjema på nettsiden.`}</li>
      <li parentName="ul">{`Fyll ut skjemafelt feil, eller la obligatoriske felt stå tomme.`}</li>
      <li parentName="ul">{`Sjekk at feltene får tydelig feilmelding som viser hvor feilen er.`}</li>
      <li parentName="ul">{`Sjekk om tidligere innfylt informasjon beholdes i skjemafeltet, med mindre det er sensitiv informasjon.`}</li>
      <li parentName="ul">{`Sjekk at disse feltene får klar beskrivelse av hva feilen er slik at brukeren kan rette feilen.`}</li>
      <li parentName="ul">{`Sjekk at feilmeldingen er kodet som tekst slik at skjermlesere kan lese feilen.`}</li>
    </ul>
    <h3>{`Ledetekster eller instruksjoner (3.3.2)`}</h3>
    <p>{`Skjemafelter har ledetekst og instruksjoner for korrekt utfylling av skjemafeltene.
Dette betyr at:`}</p>
    <ul>
      <li parentName="ul">{`Obligatoriske felter er markert som obligatoriske, enten med symbol (hvis symbolet er forklart på starten av skjemaet) eller med "obligatorisk" eller lignende som en del av label/ledetekst.`}</li>
      <li parentName="ul">{`Skjemafelt har tydelig navngivning/instruksjon for hvordan skjemafeltet skal fylles ut.`}</li>
    </ul>
    <h3>{`Forslag ved feil (3.3.3)`}</h3>
    <p>{`Gi brukeren løsningsforslag for hvordan skjemafelt skal fylles inn når brukeren har gitt feil inputverdi i et skjemafelt.`}</p>
    <ul>
      <li parentName="ul">{`Husk at forslaget skal kodes som tekst.`}</li>
      <li parentName="ul">{`Sørg for at tidligere innfylt informasjon blir lagret i skjemaet, med mindre informasjonen faller innenfor personvern eller andre sikkerhetshensyn.`}</li>
    </ul>
    <h3>{`Forhindring av feil (3.3.4)`}</h3>
    <p>{`I løsninger der brukeren utfører økonomiske transaksjoner eller inngår juridisk bindende avtaler må vi gi brukeren minst ett (helst flere) av følgende verktøy:`}</p>
    <ul>
      <li parentName="ul">{`Innsending må kunne angres en siste gang før fullstending innsending utføres.`}</li>
      <li parentName="ul">{`Skjemaet må kontrolleres for inndatafeil og brukeren må ha mulighet til å korrigere eventuelle feil de kan ha gjort.`}</li>
      <li parentName="ul">{`Det skal finnes en mekanisme som lar brukeren gjennomgå, korrigere og bekrefte informasjonen før den sendes.`}</li>
    </ul>
    <h2>{`Prinsipp 4: Robust`}</h2>
    <h3>{`Parsing/oppdeling (4.1.1)`}</h3>
    <p>{`HTML-koden må opprettholde standardisert kvalitet for å unngå utilsiktede feil eller vanskeligheter for hjelpemidler.`}</p>
    <p>{`Test dette ved å inspisere koden for nettstedet, kopier `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`html`}</code>{` elementet og lim koden inn i `}<a parentName="p" {...{
        "href": "https://validator.w3.org/#validate_by_input"
      }}>{`W3C HTML validator`}</a>{` under "validate by input".`}</p>
    <p>{`Sørg deretter for at følgende feil ikke oppstår på nettsiden:`}</p>
    <ul>
      <li parentName="ul">{`Element (name) not allowed`}</li>
      <li parentName="ul">{`Unclosed element (name)`}</li>
      <li parentName="ul">{`End tag for (name) omitted`}</li>
      <li parentName="ul">{`End tag for (name) which is not finished`}</li>
      <li parentName="ul">{`Duplicate attribute (name)`}</li>
      <li parentName="ul">{`Duplicate specification of attribute (name)`}</li>
      <li parentName="ul">{`Duplicate ID (name)`}</li>
      <li parentName="ul">{`ID (name) already defined`}</li>
      <li parentName="ul">{`Fatal Error`}</li>
    </ul>
    <h3>{`Navn, rolle, verdi (4.1.2)`}</h3>
    <p>{`HTML-elementer må være korrekt definert i koden med korrekt klassifikasjon i HTML eller skript som for eksempel WAI-ARIA tagging.
Standarden for dette kan grovt deles inn i Skjema, knapper og iFrames.`}</p>
    <p>{`Det er essensielt at ledetekst og instruksjon til skjemaelementer beskriver formålet med skjemafelt og kobles korrekt i koden.`}</p>
    <h4>{`Skjema`}</h4>
    <ul>
      <li parentName="ul">{`Sørg for at skjemaelementer er kodet som `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`input`}</code>{`, `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`select`}</code>{` eller `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`textarea`}</code>{`.`}</li>
      <li parentName="ul">{`Sørg for at skjemaet har en definert `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`title`}</code>{`i koden.`}</li>
      <li parentName="ul">{`Sørg for at skjemaelementer er knyttet sammen med ledetekst med korrekt bruk av label="" og for="".`}
        <ul parentName="li">
          <li parentName="ul">{`Om nødvendig kan man benytte aria-label og aria-labelledby.`}</li>
        </ul>
      </li>
      <li parentName="ul">{`For skjemafeltgrupper, typ personinformasjon, kontaktinformasjon eller lignende, er det best practice å bruke `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`fieldset`}</code>{`og `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`legend`}</code>{`for å klassifisere gruppe programmatisk.`}
        <ul parentName="li">
          <li parentName="ul">{`Alternativt kan man bruke role=group, aria-label og aria-labelledby.`}</li>
        </ul>
      </li>
    </ul>
    <h4>{`Knapper`}</h4>
    <p>{`Knapper skal ideelt kodes som standard HTML med `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`button`}</code>{` eller `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`input`}</code>{`.
Der dette ikke er mulig må man bruke role=button.`}</p>
    <ul>
      <li parentName="ul">{`Sørg for at knappen er koblet til ledetekst med `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`label`}</code>{`og `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`for`}</code></li>
    </ul>
    <h4>{`iFrame`}</h4>
    <p>{`Der iFrame benyttes for å presentere innhold er det viktig at ledetekst og `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`title`}</code>{` beskriver innholdet i iFrame.
Om det ikke er mulig å bruke `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`title`}</code>{`må man bruke aria-label og aria-labelledby.`}</p>
    <h3>{`Statusbeskjeder (4.1.3)`}</h3>
    <p>{`Statusbeskjeder, typ suksessmeldinger, feilmeldinger eller nedetid av tjenester må kodes slik at hjelpemidler kan motta meldingen uten at brukeren mister eller får endret kontekst.`}</p>
    <p>{`Løsningsforslag:`}</p>
    <ul>
      <li parentName="ul">{`Statusbeskjeder om at en handling er vellykket, typ slutten på en skjemainnfylling, må kodes med role="status".`}</li>
      <li parentName="ul">{`Om statusbeskjeden kommuniserer ventetilstand eller framdrift må man bruke role="log", role="progressbar" eller role="status".`}</li>
      <li parentName="ul">{`Feilmeldinger må kodes med role="alert".`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      